import {useState} from "react";

function DeleteReviewForm({onSubmit}) {
    const [id, setId] = useState(0);
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(id);
    }

    return (
        <form onSubmit={handleSubmit}>
            <label>ИД отзыва для удаления:
                <input type="number" value={id} onChange={e => setId(e.target.value)}/>
            </label><br/>
            <button>Удалить</button>
        </form>
    )
}

export default DeleteReviewForm