type LoginData = {
    login: string;
    password: string;
};

type NewUserData = {
    login: string;
    password: string;
    client: string;
    company: string;
    companyCode: number;
    isAdmin: boolean;
};

type NewFeedData = {
    theme: string;
    text: string;
}

type ReviewData = {
    theme:string,
    text:string,
    client:string,
    client_picture:string,
    pictures_path:string
}

const errorHandler = async (response: Response) => {
    if (response.status !== 200) {
        const responseData = await response.json();
        throw Error(responseData.message);
    }
};

export const API = {
    request: {
        get: async (companyCode) => {
            const response = await fetch(`/api/rx/${companyCode}`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        }
    },
    auth: {
        login: async (data: LoginData) => {
            const response = await fetch(`/api/auth`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            await errorHandler(response);
        },
        logout: async () => {
            const response = await fetch(`/api/auth`, {
                method: "DELETE",
                credentials: "include",
            });
            await errorHandler(response);
        },
    },
    user: {
        register: async (data: NewUserData) => {
            const response = await fetch(`/api/user`, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "http://192.168.0.183:3001",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            await errorHandler(response);
        },
        getCurrentUser: async () => {
            const response = await fetch(`/api/user`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        },
        delete: async (id) => {
            const response = await fetch(`/api/user/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            await errorHandler(response);
        }
    },
    feed: {
        getAll: async () => {
            const response = await fetch(`/api/feed`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        },
        getByUrl: async (url) => {
            const response = await fetch(`/api/feed/${url}`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        },
        create: async (data: NewFeedData) => {
            const response = await fetch(`/api/feed`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            await errorHandler(response);
        },
        delete: async (id) => {
            const response = await fetch(`/api/feed/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            await errorHandler(response);
        }
    },
    product: {
        getAll: async () => {
            const response = await fetch(`/api/product`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        },
        getByUrl: async (url) => {
            const response = await fetch(`/api/product/${url}`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        },
    },
    review: {
        getAll: async () => {
            const response = await fetch(`/api/review`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        },
        getById: async (feedId) => {
            const response = await fetch(`/api/review/${feedId}`, {
                credentials: "include",
                method: "GET"
            });
            await errorHandler(response);
            return await response.json();
        },
        create: async (data: ReviewData) => {
            const response = await fetch(`/api/review`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            await errorHandler(response);
        },
        delete: async (id) => {
            const response = await fetch(`/api/review/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            await errorHandler(response);
        }
    },
};