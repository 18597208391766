import "../styles/Navbar.css";
import {Link} from 'react-router-dom';
import logo from "../sources/sibprojectcom-logo.png"

const Navbar = () => {
    return (
        <nav className="navbar">
            <Link className="logo" to="/">
                <img src={logo}
                     alt="ГК «СибПроект» - консультант и поставщик современных и надежных ИТ-решений."/>
            </Link>
            <ul>
                <Link className="other" to="/products">
                    Решения
                </Link>
                <Link className="other" to="/feed">
                    Новости
                </Link>
                <Link className="other" to="/about">
                    О компании
                </Link>
                {/*<Link className="other" to="/login">*/}
                {/*    Личный кабинет*/}
                {/*</Link>*/}
                <Link className="other" to="/profile">
                    Личный кабинет
                </Link>
            </ul>
        </nav>
    );
};
export default Navbar;

