import {useState} from "react";

const NewUserData = {
    login: "",
    password: "",
    client: "",
    company: "",
    companyCode: "",
    isAdmin: false
}

function CreateUserForm({onSubmit}) {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [client, setClient] = useState("");
    const [company, setCompany] = useState("");
    const [companyCode, setCompanyCode] = useState(0);
    const [error, setError] = useState("");
    const isAdmin = false;

    const isValid = () => {
        let result = true;

        if (!login || !password || !client || !companyCode || !company) {
            setError("Все поля должны быть заполнены");
            result = false;
        }

        return result;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValid()) {
            onSubmit({
                login,
                password,
                client,
                company,
                companyCode,
                isAdmin
            });
        }
    };

    return <form className="create_user" onSubmit={handleSubmit}>Создание пользователей<br/>
        <label>Логин:
            <input value={login} onChange={e => setLogin(e.target.value)}/>
            {/*<input/>*/}
        </label><br/>
        <label>Пароль:
            <input value={password} onChange={e => setPassword(e.target.value)}/>
        </label><br/>
        <label>Клиент:
            <input value={client} onChange={e => setClient(e.target.value)}/>
        </label><br/>
        <label>Компания:
            <input value={company} onChange={e => setCompany(e.target.value)}/>
        </label><br/>
        <label>Код компании (поле Код на карточке Организации в нашей системе RX):
            <input type="number" min="0" value={companyCode} onChange={e => setCompanyCode(e.target.value)}/>
        </label><br/>
        {error &&
            <label>{error}</label>}<br/>
        <button type="submit">Создать</button>
    </form>
}

export {CreateUserForm, NewUserData}