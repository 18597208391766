import {useState} from "react";
import TableFooter from "./TableFooter";
import useTable from "./useTable";

const Table = ({data, rowsPerPage}) => {
    const [page, setPage] = useState(1);
    const {slice, range} = useTable(data, page, rowsPerPage);

    const getCreatedInFormat = (str) => {
        let result = '';
        if (!str)
            return result;

        let date = str.split('T')[0];
        let time = str.split('T')[1];
        result = date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0];
        result += ' ' + time.split(':')[0] + ':' + time.split(':')[1]

        return result;
    }

    const getRowStyle = (value) => {
        if (value.importance === true && value.status === "В работе")
            return "high-importance"

        if (value.status === "Отправлено клиенту" || value.status === "В работе")
            return "in-work"

        return "abort"
    }

    return (
        <>
            <table className="table">
                <thead>
                <tr>
                    <th>Номер</th>
                    <th>*</th>
                    <th>Тип</th>
                    <th>Тема</th>
                    <th>Дата создания</th>
                    <th>Статус обращения</th>
                    <th>Описание</th>
                </tr>
                </thead>
                <tbody>
                {slice.map((elem, index) => (
                    <tr key={index} className={getRowStyle(elem)}>
                        <td>{elem.number}</td>
                        {elem.importance === true ? <td>!</td> : <td></td>}
                        <td>{elem.type}</td>
                        <td>{elem.subject}</td>
                        <td>{getCreatedInFormat(elem.created)}</td>
                        <td>{elem.status}</td>
                        <td>{elem.description}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} buttonRange={3}/>
        </>
    );
};

export default Table