const Footer = () => {
    return (
        <div>
            <div className="contacts">
                <div>Телефон/факс: (383) 363-08-61</div>
                <div>Адрес: 630090 Россия, г. Новосибирск, ул. Инженерная, д. 16<br/>
                    Е-mail: office@sibprojectcom.ru</div>
            </div>
            <br/>
            © Группа Компаний «СибПроект», {new Date().getFullYear()}
        </div>
    );
};
export default Footer;