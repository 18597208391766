import React, {useState} from "react";
import {API} from "../../../../services/api";
import DeleteReviewForm from "./DeleteReviewForm";

const DeleteReview = () => {
    const [result, setResult] = useState("");
    const [error, setError] = useState("");
    const onSubmit = (data) => {
        const deleteReviewRequest = async () => {
            setResult("")
            setError("");
            try {
                await API.review.delete(data);
                setResult("Новость удалена");
            } catch (e) {
                if (e) {
                    setError(`Ошибка: ${e}`);
                }
            }
        };
        deleteReviewRequest().then()
            .catch(err => setError(err));
    }

    return <div>
        <DeleteReviewForm onSubmit={onSubmit}/>
        {result && <div className="result">{result}</div>}
        {error && <div className="error">{error}</div>}
    </div>

}

export default DeleteReview