import {useState} from "react";

const NewReviewData = {
    theme:"",
    text:"",
    client:"",
    client_picture:"",
    pictures_path:""
}

function CreateReviewForm ({onSubmit}) {
    const [theme, setTheme] = useState("");
    const [text, setText] = useState("");
    const [client, setClient] = useState("");
    const [client_picture, setPng] = useState("");
    const [error, setError] = useState("");

    const isValid = () => {
        let result = true;

        if (!theme || !text || !client || !client_picture ) {
            setError("Все поля должны быть заполнены");
            result = false;
        }

        return result;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValid()) {
            onSubmit({
                theme, text, client, client_picture, client_picture
            });
        }
    };

    return <form className="create_Review" onSubmit={handleSubmit}>Создание новостей<br/>
        <label>Заголовок новости:</label><br/>
        <input value={theme} onChange={e => setTheme(e.target.value)}/>
        <br/>
        <label>Текс:</label><br/>
        <textarea value={text} onChange={e => setText(e.target.value)}/>
        <br/>
        <label>Клиент:</label><br/>
        <input value={client} onChange={e => setClient(e.target.value)}/>
        <label>Наименование картинки:</label><br/>
        <input value={client_picture} onChange={e => setPng(e.target.value)}/>
        {error &&
            <label>{error}</label>}<br/>
        <button type="submit">Создать</button>
    </form>
}

export {NewReviewData, CreateReviewForm}