import {Link} from "react-router-dom";
import LoadPictures from "./LoadPictures";
import React, {useEffect, useState} from "react";
import {API} from "../../services/api";

const arrow_left = require("../../sources/arrow-left.png");
const arrow_right = require("../../sources/arrow-right.png");

const ReviewsList = () => {
    // const [basic] = useState([{
    //     client: "Сибирский Цементный завод",
    //     theme: "Перенос на новую систему электронного документооборота холдинговой компании Сибирский цемент",
    //     text: "Текст текст текст текст",
    //     client_picture: "sibcem.png"
    // }]);

    const [reviews, setReviews] = useState();
    const [index, setIndex] = useState(0);
    const [perPage] = useState(3);

    useEffect(() => {
        if (!reviews) {
            getReviews();
        }
    });

    const getReviews = async () => {
        let res = await API.review.getAll();
        setReviews(res);
    }

    const nextElement = (array) => {
        if (index + 3 >= array.length) return
        setIndex(index + 1);
    };

    const previousElement = () => {
        if (index === 0) return
        setIndex(index - 1);
    };

    const render = (array) => {
        return (<div>
            {array && <div className="product_list_short">
                {index === 0 ?
                    <a className="review_arrow" style={{"animation": "fadeInFromNone 0.5s ease-out forwards"}}
                       onClick={() => previousElement()}>
                        <img style={{"animation": "fadeInFromNone 0.5s ease-out forwards"}} src={arrow_left}
                             alt="Left arrow button"/>
                    </a> : <a className="review_arrow" onClick={() => previousElement()}>
                        <img src={arrow_left} alt="Left arrow button"/>
                    </a>}
                {array.slice(index, index + perPage).map((rew, index) => {
                    return <div key={index} className="review_card">
                        <Link to={`/feed/${rew.url}`}>
                            <img src={LoadPictures(rew.client_picture)} alt="отзыв"/><br/>
                            <span>{rew.client}</span>
                        </Link>
                    </div>
                })}
                {index + perPage >= array.length ?
                    <a className="review_arrow" style={{"animation": "fadeInFromNone 0.5s ease-out forwards"}}>
                        <img src={arrow_right}
                             alt="Arrow arrow button"/>
                    </a> : <a className="review_arrow" onClick={() => nextElement(array)}>
                        <img src={arrow_right} alt="Arrow arrow button"/>
                    </a>}
            </div>}
        </div>)
    }

    return (<div>
        {(reviews && reviews.length !== 0) ? 
        render(reviews) : <></>}
    </div>);
}
export default ReviewsList;