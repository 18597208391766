import {Link} from "react-router-dom";
import LoadPictures from "./LoadPictures";
import {useEffect, useState} from "react";
import {API} from "../../services/api";
// import products from "../sources/products.json";

const ProductsList = () => {
    const [products, setProducts] = useState();

    useEffect(() => {
        if (!products) {
            getProducts();
        }
    });

    const getProducts = async () => {
        let res = await API.product.getAll();
        setProducts(res);
    }

    const renderShort = () => {
        return (
            <div className="product_list_short">
                {products &&
                    products.map(function (prod) {
                    return <div key={prod.className} className="card" id={prod.className}>
                        <Link to={`/products/${prod.url}`}>
                            <img src={LoadPictures(prod.full_picture)} alt={prod.alt_img}/>
                        </Link>
                    </div>
                })}
            </div>
        );
    }

    const render = () => {
        return (
            <div className="product_list">
                {products &&
                    products.map(function (prod) {
                        return <div key={prod.className} className="card" id={prod.className}>
                            <Link to={`/products/${prod.url}`}>
                                <img src={LoadPictures(prod.full_picture)} alt={prod.alt_img}/>
                                <br/>
                                <span>{prod.description}</span>
                            </Link>
                        </div>
                    })}
            </div>
        );
    }

    return (
        <div>
            {window.location.pathname === "/" ?
                renderShort()
                : render()}
        </div>
    );
}
export default ProductsList;