import { useState } from "react";
import "../../styles/Login.css";
// import {bool, string} from 'prop-types';

const LoginFormData = {
    login: "",
    password: ""
}

function LoginForm({onSubmit}) {
    const [login, setLogin] = useState("");
    const [loginError, setLoginError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const isValid = () => {
        let result = true;

        // очищаем ошибки
        setLoginError("");

        if (login.length === 0) {
            setLoginError("Логин не может быть пустым.");
            result = false;
        }

        setPasswordError("");

        if (password.length === 0) {
            setPasswordError("Пароль не может быть пустым.");
            result = false;
        }

        return result;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValid()) {
            onSubmit({
                login,
                password
            });
        }
    };

    return <div >
        <div className="header">Вход в личный кабинет для клиентов ГК «СибПроект»</div>
        <div className="form_div">
            <form onSubmit={handleSubmit} className="form">
                <div className="div_form">
                    <label>Логин:
                        <input value={login} onChange={e => setLogin(e.target.value)}/>
                    </label>
                    {loginError && <div className="error">
                        {loginError}
                    </div>}
                </div>
                <div>
                    <label>Пароль:
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                    </label>
                    {passwordError && <div className="error">
                        {passwordError}
                    </div>}
                </div>
                <button type="submit">Войти</button>
            </form>
        </div>
    </div>;
}

export {LoginFormData, LoginForm}