import {API} from "../services/api";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AdminForm from "../components/Profile/Admin/AdminForm";
import UserForm from "../components/Profile/User/UserForm";

import("../styles/Profile.css")

const Profile = () => {
    const navigate = useNavigate();
    const [result, setResult] = useState("");
    const [error, setError] = useState("");
    const [isAdmin, setAdmin] = useState(false);
    const [isLogged, setLogged] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        const userRequest = async () => {
            setLogged(false);
            setResult("");
            setError("");
            setUser();
            setAdmin();
            try {
                const user = await API.user.getCurrentUser();
                setResult(`${user.login}`);
                setLogged(true);
                setUser(user);
                setAdmin(user.isAdmin);
            } catch (e) {
                if (e) {
                    if (!user)
                        navigate('/login');
                    setError(e.message);
                }
            }
        };
        userRequest();

    }, []);

    const handleLogout = () => {
        const logoutRequest = async () => {
            try {
                await API.auth.logout();
                setLogged(false);
                setResult("");
                setTimeout(() => {
                    navigate("/login");
                }, 500);
            } catch (e) {
                if (e instanceof Error) {
                    setError(e.message);
                }
            }
        };
        logoutRequest();
    };

    return <>
        {isLogged && <div className="profile">
            {result && <div className="header">{user.client}</div>}
            {error && <div className="error">{error}</div>}
            <div className="page">
                <button className="logout" onClick={handleLogout}>Выход из личного кабинета</button>
                {isAdmin ?
                <AdminForm/> : <UserForm user={user}/>}
            {/*    <div className="content">*/}
            {/*        <div className="user">*/}
            {/*            <img className="icon" src={userLogo} alt="Иконка профиля."/>*/}
            {/*            <div>Информация о клиенте:*/}
            {/*                {user.client && <div>{user.client}</div>}*/}
            {/*                {user.company && <div>{user.company}</div>}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <button onClick={handleLogout}>Выход из личного кабинета*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <div className="requests">*/}
            {/*        {isAdmin ?*/}
            {/*            <div className="create_admin_panel">Область администрирования*/}
            {/*                <CreateUser/>*/}
            {/*                <CreateFeed/>*/}
            {/*                <DeleteFeed/>*/}
            {/*            </div>*/}
            {/*            : <div>Обращения клиента*/}
            {/*                <Spinner/>*/}
            {/*                {requests &&*/}
            {/*                    <StringRequestToJson/>}*/}
            {/*            </div>}*/}
            {/*    </div>*/}
            </div>
        </div>}
    </>;
}

export default Profile;