import {useEffect} from "react";

const TableFooter = ({range, setPage, page, slice, buttonRange}) => {
    let startButtonRange = page - buttonRange - 1 < 0 ? 0 : page - buttonRange - 1;

    useEffect(() => {
        if (slice.length < 1 && page !== 1) {
            setPage(page - 1);
        }
    }, [slice, page, setPage]);
    return (
        <div className="tb-footer">
            <button onClick={() => setPage(1)}>В начало</button>
            <button onClick={() => setPage(
                page - buttonRange < 1 ? 1 : page - buttonRange
            )}>Назад
            </button>
            {range.map((elem, index) => (
                <button
                    key={index}
                    className={
                        page === elem ? "active" : "not-active"
                    }
                    onClick={() => setPage(elem)}>
                    {elem}
                </button>
            )).slice(startButtonRange, page + buttonRange)}
            <button onClick={() => setPage(
                page + buttonRange >= range.length ? range.length : page + buttonRange
            )}>Вперед
            </button>
            <button onClick={() => setPage(range.length)}>В конец</button>
            <span className="footer-text">Всего страниц: {range.length}</span>
        </div>
    );
};

export default TableFooter