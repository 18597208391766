import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { API } from "../../services/api";
import Spinner from "../Spinner";


const FeedCard = () => {
    const params = useParams();
    const feedUrl = params.url;
    const [feed, setFeed] = useState();
    const [error, setError] = useState();

    const GetFeedByUrl = async () => {
        if (feedUrl) {
            let res = await API.feed.getByUrl(feedUrl);
            setFeed(res);
        }
        else
            setError("Такой новости нет.")
    }

    useEffect(() => {
        if (!feed) {
            GetFeedByUrl();
        }
    });

    const renderFeed = () => {
        return (
            <div className="feed">
                <div className="theme">
                    {feed.theme}
                </div>
                <div className="text">
                    {renderText()}
                </div>
            </div>);
    }

    const renderText = () => {
        if (error)
            return <div>{error}</div>

        let result = [];
        let p = feed.text.split("/");
        p.forEach(e => {
            if (e.split(" ")[0] == "BOLD")
                result.push(<div className="bold">{e.split("BOLD")[1]}</div>);
            else
                result.push(<div className="block">{e}</div>);
        });
        // if(!Array.isArray(feed.text))
        //     result = <div>{feed.text}</div>
        // else
        //     for (let key in feed.text) {
        //         let style = feed.text[key].split("/")[0]

        //         if (!style)
        //             result += <div>ffff {feed.text[key]}</div>

        //         if (style === "BOLD")
        //             result += <div className="bold">{feed.text[key]}</div>

        //         if (style === "CURL")
        //             result += <div className="curl">{feed.text[key]}</div>
        //     }

        return result
    }

    return <div className="main_div">
        {(feed) ? (
            <div className="feed_card">
                {renderFeed()}
            </div>) : (
            <p className="error">
                <Spinner />
            </p>
        )}
    </div>
}

export default FeedCard;