const Spinner = (loading) => {
   return <div className="spinner">
        <span style={{"--i": 1}}>З</span>
        <span style={{"--i": 2}}>а</span>
        <span style={{"--i": 3}}>г</span>
        <span style={{"--i": 4}}>р</span>
        <span style={{"--i": 5}}>у</span>
        <span style={{"--i": 6}}>з</span>
        <span style={{"--i": 7}}>к</span>
        <span style={{"--i": 8}}>а</span>
        <span style={{"--i": 9}}>.</span>
        <span style={{"--i": 10}}>.</span>
        <span style={{"--i": 11}}>.</span>
    </div>
}

export default Spinner;