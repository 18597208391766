import React, { useEffect, useState } from "react";
import { API } from "../../services/api";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";

function FeedList() {
    const [feeds, setFeeds] = useState();

    useEffect(() => {
        if (!feeds) {
            getFeeds();
        }
    });

    const getFeeds = async () => {
        let res = await API.feed.getAll();
        setFeeds(res);
    }

    const splitFeedText = (text) => {
        let parts = text.split('/');
        if (parts[0].length <= 250)
            return parts[0];

        if (text.length < 250)
            return text;

        let i = 250;
        while (text[i] != " ")
            i++;

        let subText = text.substring(0, 250);
        return subText + "... ";
    }

    const renderFeed = feed => {
        return (<div className="card" key={feed.theme}>
            <Link to={`/feed/${feed.url}`}>
                <p className="theme">
                    {feed.theme}
                </p>
                <span className="text">
                    {splitFeedText(feed.text)}
                </span>
            </Link>
        </div>);
    };

    return (<div className="main_div">
        {feeds ? (feeds.length > 0 ? <ul>
            {feeds.map(feed => renderFeed(feed)).reverse()}
        </ul> : <p className="error">Пока что тут ничего нет.</p>) : <p className="error">
            <Spinner />
        </p>}
    </div>);
}

export default FeedList;