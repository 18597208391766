import {Outlet} from "react-router-dom";
import "../styles/Home.css"

const Home = () => {
    return (
        <div className="home">
            <span className="home_header">
                ГК «СибПроект» - поставщик современных и надёжных ИТ-решений.
            </span><br/>
            <span className="home_text">
                Сертифицированный партнер компании Directum.
            </span>
            <Outlet/>
        </div>
    );
};
export default Home;
