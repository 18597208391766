import "../../styles/ProductCard.css"
import {useParams} from "react-router-dom";
import LoadPictures from "../Product/LoadPictures";
import {useEffect, useState} from "react";
import {API} from "../../services/api";

const ProductCard = () => {
    const params = useParams();
    const prodUrl = params.url;
    const [product, setProduct] = useState();

    useEffect(() => {
        if (!product) {
            getProduct();
        }
    });

    const getProduct = async () => {
        console.log(prodUrl)
        let res = await API.product.getByUrl(prodUrl);
        setProduct(res);
    }

    const informationInUl = (ulItem) => {
        let result = [];
        for (let liItem in ulItem) {
            result.push(
                <li key={liItem}>
                    <div className="li_header">
                        {ulItem[liItem].theme}
                    </div>
                    <div>
                        {ulItem[liItem].text}
                    </div>
                </li>);
        }
        return result;
    }

    return (
        <div className="page">
            {product &&
                <div className="product_card">
                    <div className="header">
                        {LoadPictures(product.picture) &&
                            <img className="logo" src={LoadPictures(product.full_picture)} alt={product.alt_img}/>}
                    </div>
                    <div className="text">
                        <div className="description" id={product.className}>
                            {product.description}
                        </div>
                    </div>
                    <div className="additional">
                        <ul id={product.className}>
                            {product.additional_information &&
                                informationInUl(product.additional_information)}
                        </ul>
                    </div>
                    <div className="link">
                        <p onClick={openLink} id={product.className}>Подробнее...</p>
                    </div>
                </div>}
        </div>);

    function openLink() {
        let p = product;
        switch (p.className) {
            case "rx":
                window.open('https://www.directum.ru/products/directum');
                break;
            case "ario":
                window.open('https://www.directum.ru/products/ario');
                break;
            case "hr":
                window.open('https://www.directum.ru/products/hr_pro');
                break;
            case "projects":
                window.open('https://www.directum.ru/products/directum-projects');
                break;
            default:
                break;
        }
        return null;
    }
};
export default ProductCard;
