import ECMlogo from "../sources/ecmLogo.png";
import {Outlet} from "react-router-dom";
import "../styles/Feed.css"

const Feed = () => {
    return (
        <div className="feed">
            <p className="header">Новости компании и электронного документооборота</p>
            <div className="main">
                <Outlet/>
                <img onClick={ECM} src={ECMlogo} className="ECMJournal"
                     alt="ECM-Journal - Просто об электронном документообороте"/>
            </div>
        </div>
    );
};

function ECM() {
    window.open('https://ecm-journal.ru/');
    return null;
}

export default Feed;