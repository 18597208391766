import "../styles/About.css"

const About = () => {
    return (
        <div>
            <p className="about_header">Группа Компаний «СибПроект»
            </p>
            <div className="about">
            <div className="main">
                <div className="text_start">
                    Наш коллектив единомышленников, обладающих большим опытом работы на рынке информационных систем
                    управления, осуществляет свою деятельность с 2002 года.
                </div>
                <div className="text">
                    За это время мы заработали устойчивую репутацию команды, способной найти решение поставленных перед
                    ней
                    задач. На сегодняшний день Компания позиционирует себя не только как поставщик современных и
                    надёжных
                    ИТ-решений, но и как консультант, совместно с заказчиком определяющий бизнес-цели проекта внедрения
                    информационной системы и способы её реализации.
                </div>
                <div className="text">
                    Огромный опыт решения комплексных задач наших клиентов, проверенные на практике технологии и
                    профессионализм специалистов позволяют оказывать широкий спектр услуг: от разработки
                    бизнес-архитектуры
                    и совершенствования бизнес-процессов до создания информационных систем управления «с нуля». За годы
                    работы специалистами Компании выполнено более пятидесяти проектов для предприятий различных форм
                    собственности и направлений деятельности: от госучреждений и мелких торговых компаний до крупных
                    банков
                    и финансово-промышленных холдингов.
                </div>
                <div className="text_end">
                    Благодаря высокому качеству предоставляемых Компанией услуг наши клиенты снова и снова доверяют нам
                    решение возникающих перед ними задач.
                </div>
            </div>
            <div className="additional">
                {/*<div>*/}
                {/*    <span className="header"> Миссия нашей компании*/}
                {/*    </span>*/}
                {/*    <div className="text">*/}
                {/*        <p>*/}
                {/*            Помощь предприятиям регионов России в повышении уровня управления и эффективности*/}
                {/*            бизнес-процессов за счет практического внедрения предлагаемых нами информационных технологий.*/}
                {/*        </p>*/}
                {/*        <p>*/}
                {/*            Предоставление клиентам качественной консультационной и технической поддержки в процессе*/}
                {/*            эксплуатации информационно-программных комплексов.*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <span className="header">Стратегия компании*/}
                {/*    </span>*/}
                {/*    <p className="text">*/}
                {/*        Для достижения наиболее продуктивной и качественной работы с нашими клиентами, мы выстраиваем*/}
                {/*        взаимоотношения на равноправной и долгосрочной основе. Поэтому клиентская политика Компании*/}
                {/*        направлена не только на успешное завершение конкретных проектов, но и на совершенствование*/}
                {/*        уровня постпродажного сервиса и консультационно-технической поддержки.*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*<div className="contacts">*/}
                {/*    <span className="header">Наши контакты*/}
                {/*    </span>*/}
                {/*    <div className="text">*/}
                {/*        <div>Телефон/факс: (383) 363-08-61</div>*/}
                {/*        <div>Адрес: 630090 Россия, г. Новосибирск, ул. Инженерная, д. 16<br/>*/}
                {/*            Е-mail: office@sibprojectcom.ru</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            </div>
        </div>
    );
};
export default About;
