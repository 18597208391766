import CreateUser from "./User/CreateUser";
import CreateFeed from "./Feed/CreateFeed";
import DeleteFeed from "./Feed/DeleteFeed";
import CreateReview from "./Review/CreateReview";
import DeleteReview from "./Review/DeleteReview";

const AdminForm = () => {
    return <div className="create_admin_panel">Область администрирования
        <CreateUser/>
        <CreateFeed/>
        <DeleteFeed/>
        <CreateReview/>
        <DeleteReview/>
    </div>
}

export default AdminForm;