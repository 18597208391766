import React, {useState} from 'react';
import "../../styles/Login.css";
import {useNavigate} from "react-router-dom";
import {LoginFormData, LoginForm} from "./LoginForm";
import {API} from "../../services/api";

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");

    const onSubmit = (data: LoginFormData) => {
        const authRequest = async () => {
            setError("");
            try {
                await API.auth.login(data);
                setTimeout(() => {
                    navigate("/profile");
                }, 500);
            } catch (e) {
                if (e) {
                    setError("Неверный логин или пароль");
                }
            }
        };
        authRequest()
    };

    return (
        <div className="login">
            <LoginForm onSubmit={onSubmit}/>
            {error && <div className="error">{error}</div>}
        </div>
    );
};

export default Login;