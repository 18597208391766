import {useState} from "react";

const NewFeedData = {
    theme:"",
    text:""
}

function CreateFeedForm ({onSubmit}) {
    const [theme, setTheme] = useState("");
    const [text, setText] = useState("");
    const [error, setError] = useState("");

    const isValid = () => {
        let result = true;

        if (!theme || !text ) {
            setError("Все поля должны быть заполнены");
            result = false;
        }

        return result;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isValid()) {
            onSubmit({
                theme, text
            });
        }
    };

    return <form className="create_feed" onSubmit={handleSubmit}>Создание новостей<br/>
        <label>Заголовок новости:</label><br/>
        <input value={theme} onChange={e => setTheme(e.target.value)}/>
        <br/>
        <label>Текс:</label><br/>
        <textarea value={text} onChange={e => setText(e.target.value)}/>
        {error &&
            <label>{error}</label>}<br/>
        <button type="submit">Создать</button>
    </form>
}

export {NewFeedData, CreateFeedForm}