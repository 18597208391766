import reviewsList from "./ReviewsList";

const RXLogo = require("../../sources/rx-logo---vertical@4x.png");
const ArioOneLogo = require("../../sources/ario-one---coloured---vertical@4x.png");
const HRLogo = require("../../sources/hr-pro2---coloured---vertical@4x.png");
const ProjectsLogo = require("../../sources/projects---coloured---vertical-3@4x.png");
const RXLogoHoriz = require("../../sources/rx-logo---horizontal@4x.png");
const ArioOneLogoHoriz = require("../../sources/ario-one---coloured---horizontal--------------@4x.png");
const HRLogoHoriz = require("../../sources/hr-pro---coloured---horizontal-small@4x.png");
const ProjectsLogoHoriz = require("../../sources/projects - -coloured - -horizontal-14x.png");
const SibcemReviewLogo = require("../../sources/sibcem.png");
const UevReviewLogo = require("../../sources/uev_main_logo.png");

const LoadPictures = (picture) => {
    let img;
    switch (picture) {
        case "rx-logo---vertical@4x.png":
            img = RXLogo;
            break;
        case "ario-one---coloured---vertical@4x.png":
            img = ArioOneLogo;
            break;
        case "hr-pro2---coloured---vertical@4x.png":
            img = HRLogo;
            break;
        case "projects---coloured---vertical-3@4x.png":
            img = ProjectsLogo;
            break;
        case "rx-logo---horizontal@4x.png":
            img = RXLogoHoriz;
            break;
        case "ario-one---coloured---horizontal--------------@4x.png":
            img = ArioOneLogoHoriz;
            break;
        case "hr-pro---coloured---horizontal-small@4x.png":
            img = HRLogoHoriz;
            break;
        case "projects---coloured---horizontal-1@4x.png":
            img = ProjectsLogoHoriz;
            break;
        case "sibcem.png":
            img = SibcemReviewLogo;
            break;
        case "uev_main_logo.png":
            img = UevReviewLogo;
            break;
        default:
            img = undefined;
    }
    return img;
}

export default LoadPictures;