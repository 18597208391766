import React, {useState} from "react";
import {CreateUserForm, NewUserData} from "./CreateUserForm";
import {API} from "../../../../services/api";

const CreateUser = () =>{
    const [result, setResult] = useState("");
    const [error, setError] = useState("");

    const onSubmit = (data: NewUserData) => {
        const userRequest = async () => {
            setResult("")
            setError("");
            try {
                await API.user.register(data);
                setResult("Пользователь создан");
            } catch (e) {
                if (e) {
                    setResult("")
                    setError(`Ошибка: ${e.text}`);
                }
            }
        };
        userRequest().then()
            .catch(err => setError(err));
        // console.log(data);
    }

    return <div>
        <CreateUserForm onSubmit={onSubmit}/>
        {result && <div className="result">{result}</div>}
        {error && <div className="error">{error}</div>}
    </div>
}

export default CreateUser;