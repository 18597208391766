import "../styles/Products.css"
import directumLogo from "../sources/logo-directum-2019---horizontal@4x.png";
import {Outlet} from "react-router-dom";
import ProductsList from "../components/Product/ProductsList";
import ReviewsList from "../components/Product/ReviewsList";

const Products = () => {
    return (<div>
        {window.location.pathname === "/" ? <div className="product">
            <div className="header_product">
                <span>Внедрение интеллектуальных решений</span>
                <img src={directumLogo} onClick={Directum} className="directum_logo"
                     alt="Directum — интеллектуальные цифровые процессы и документы"/>
            </div>
            <ProductsList/>
            <div className="header_product">
                <span>Наши клиенты</span>
            </div>
            <ReviewsList/>
        </div> : <div className="product">
            <Outlet/>
        </div>}
    </div>);
};

function Directum() {
    window.open('https://www.directum.ru/');
    return null;
}

export default Products;