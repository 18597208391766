import './App.css';
import Navbar from "./components/Navbar";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products"
import Footer from "./components/Footer";
import ProductsList from "./components/Product/ProductsList";
import ProductCard from "./components/Product/ProductCard";
import Feed from "./pages/Feed";
import FeedList from "./components/Feed/FeedList";
import Login from "./components/Login/Login";
import Profile from "./pages/Profile";
import FeedCard from "./components/Feed/FeedCard";

function App() {
    return (
        <body>
        <header>
            <Navbar/>
        </header>
        <main>
            <Routes>
                <Route exact path='/' element={<Home/>}>
                    <Route index element={<Products/>}/>
                </Route>
                <Route path="/products" element={<Products/>}>
                    <Route index element={<ProductsList/>}/>
                    <Route path=":url" element={<ProductCard/>}/>
                </Route>
                <Route exact path='/feed' element={<Feed/>}>
                    <Route index element={<FeedList/>}/>
                    <Route path=":url" element={<FeedCard/>}/>
                </Route>
                <Route exact path='/about' element={<About/>}/>
                <Route exect path='/login' element={<Login/>}/>
                <Route exect path='/profile' element={<Profile/>}/>
            </Routes>
        </main>
        <footer>

        </footer>
        <div className="footer">
            <Footer/>
        </div>
        </body>
    );
}

export default App;
