import {useEffect, useState} from "react";
import {API} from "../../../services/api";
import Table from "./Table/Table";
import CreateUser from "../Admin/User/CreateUser";
import CreateFeed from "../Admin/Feed/CreateFeed";
import DeleteFeed from "../Admin/Feed/DeleteFeed";
import userLogo from "../../../sources/profile icon.png";
import Spinner from "../../Spinner";

const UserForm = (user) => {
    const [requests, setRequests] = useState();
    const [loading, isLoading] = useState(true);
    const [table, setTable] = useState();

    useEffect(() => {
        const getRequests = async () => {
            if (user && user.user.companyCode) {
                let code = user.user.companyCode;
                let result = await API.request.get(code);
                setRequests(result)
            }
        };
        getRequests();

        if (requests !== undefined) {
            let data = JSON.parse(requests);

            let reversedData = data.requests;

            if (reversedData.length === 0) {
                isLoading(false);
                return <div>У клиента еще не было обращений</div>
            }

            if (reversedData.length === undefined) {
                reversedData = [];
                for (const key in data) {
                    reversedData.push(data[key]);
                }
            }

            isLoading(false);
            setTable(reversedData.reverse())
        }

    },[requests])

    // const StringRequestToJson = (e) => {
    //     if (requests !== undefined) {
    //         let data = JSON.parse(requests);
    //
    //         let reversedData = data.requests;
    //
    //         if (reversedData.length === 0) {
    //             isLoading(false);
    //             return <div>У клиента еще не было обращений</div>
    //         }
    //
    //         if (reversedData.length === undefined) {
    //             reversedData = [];
    //             for (const key in data) {
    //                 reversedData.push(data[key]);
    //             }
    //         }
    //
    //         isLoading(false);
    //         return <Table data={reversedData.reverse()} rowsPerPage={15}/>
    //     }
    // }

    return <div>
        <div className="content">
            <div className="user">
                <img className="icon" src={userLogo} alt="Иконка профиля."/>
                <div>Информация о клиенте:
                    {user.client && <div>{user.client}</div>}
                    {user.company && <div>{user.company}</div>}
                </div>
            </div>
        </div>
        <div className="requests">
            <div>Обращения клиента
                {loading && <Spinner/>}
                {table &&
                    <Table data={table} rowsPerPage={15}/>
                }
            </div>
        </div>
    </div>
}

export default UserForm;